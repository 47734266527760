'use client';

import React, { useEffect } from 'react';
import Button from '@/components/Button';
import Link from 'next/link';
import { logger } from '@/Util/globals';

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    logger('error')(error);
  }, [error]);

  return (
    <div className="p-xs-3">
      <h2>There was an error during loading the page...</h2>
      <Button color="primary" className="mb-xs-3" onClick={() => reset()}>
        Please try again
      </Button>
      <div>
        <Link prefetch={false} href="/">
          or go back to Home
        </Link>
      </div>
    </div>
  );
}
